body {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.grid-container {
  display: grid;
}

.app-bar {
  position: fixed;
  width: 100%;
  height: 67px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  justify-content: space-between;
  z-index: 999;
}

.app-bar-list {
  padding-right: 15px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 100%;
}

.profile-details {
  margin-left: 51px;
}

.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #01222d;
}

.profile-name {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #01222d;
}

.profile-img {
  height: 46px;
  width: 46px;
  border-radius: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 5px solid;
}

.main-body {
  height: 100vh;
  display: grid;
  padding-top: 68px;
}

.main-content {
  grid-template-columns: 240px 1fr;
}

.content {
  background: #f7f7f7;
  margin-left: 240px;
  overflow: auto;
  box-sizing: border-box;
}

.main-content-no-sidebar {
  margin-left: unset;
}

.logo-content {
  height: 67px;
  width: 240px;
  padding-top: 24px;
  padding-left: 33px;
  display: inline-flex;
  background: #01222d;
}

.hamburger-icon {
  margin-right: 23px;
  width: 18.94px;
  height: 16.63px;
  cursor: pointer;
}

.side-icon-chat {
  height: 18px;
  width: 18px;
  margin-right: 13px;
}

.side-icon-plan {
  height: 20px;
  width: 20px;
  margin-right: 13px;
}

.side-icon-management {
  height: 19.18px;
  width: 25.26px;
  margin-right: 13px;
}

.side-navigation {
  width: 100%;
  font-size: 16px;
  background: #01222d;
  width: 240px;
  position: fixed;
  height: 100%;
}

.side-navigation li {
  counter-increment: section;
  counter-reset: section;
}

.side-navigation .parent-title a:before {
  content: counters(section, "");
  opacity: 0;
  padding-left: 19px;
}

.single-parent {
  display: inline-flex;
  padding-left: 25px;
}

.parentActive .single-parent {
  padding-bottom: 19px;
}

.side-navigation .parent-title a.single-parent:before {
  content: none;
}

.side-navigation .parent-title .child-menu a:before {
  content: none;
}

.parent-title {
  border-bottom: 1px solid #737878;
  padding-top: 19px;
}

.parent-title.inactive {
  padding-bottom: 19px;
}

.side-navigation li:hover,
.parentActive {
  background: rgba(255, 255, 255, 0.2);
}

.parentActive .child-menu {
  padding-top: 19px;
}

.side-navigation a,
.child-menu a {
  color: #ffffff !important;
}

.side-navigation a:hover,
.child-menu a:hover {
  text-decoration: none !important;
}

.child-menu li:hover,
.active {
  background: rgba(255, 255, 255, 0.3);
}

.parent-menu {
  display: flex;
}

.child-menu li {
  padding-left: 70px;
  height: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.inactive ul {
  display: none;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #232f3e;
}

.h-100vh {
  height: 100vh;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100% !important;
}

.w-fit {
  width: fit-content !important;
}

.w-25 {
  width: 25% !important;
}
.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex !important;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.m-0 {
  margin: 0 !important;
}
.m-auto {
  margin: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.m-search {
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.p-0 {
  padding: 0;
}

.p-2 {
  padding: 0.5rem;
}
.pl-3 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}

.pe-3 {
  padding-right: 1rem;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}
.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}
.icon-link {
  display: inline-flex;
  align-items: center;
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer;
}

.secondaryButton {
  color: #077398;
}

.previewOption {
  background: #f0f1f2;
  border-radius: 4px;
  align-items: baseline;
  padding: 12px 0px;
  justify-content: right;
  margin: 0 10px;
}

.previewLink {
  margin-right: 21px;
  cursor: pointer;
}

.previewLink img {
  margin-right: 12px;
}

.queryDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #979797;
  margin-left: 6px;
}

.confirmationModal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  background-color: #00000066;
}

.confirmationBox {
  background: #ffffff;
  border-radius: 4px;
  width: 647px;
  text-align: center;
  margin: auto;
  margin-top: 15rem;
  margin-bottom: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 50px;
}

.confirmationMessage {
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: #01222d;
  margin-bottom: 7px;
}

.confirmationSubMessage {
  font-size: 14px;
  line-height: 17px;
  color: #697576;
  white-space: pre-line;
  line-height: 1.6rem;
}

.subMessageSection {
  display: inline-flex;
}

.successIcon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  margin-top: 4px;
}

.failureIcon {
  color: red;
}

.confirmationFooter {
  margin-top: 1rem;
  display: flex !important;
  grid-gap: 2rem;
  & button {
    width: 50%;
  }
}

.gap-2 {
  gap: 2rem;
}

.cropTitle {
  font-size: 16px;
  line-height: 24px;
  color: #01222d;
  margin-bottom: 7px;
}

.cropName {
  color: #01222d;
  margin-bottom: 7px;
}

.cropSection {
  text-decoration: underline;
}

.cropName {
  color: #077398;
  text-decoration-color: #077398;
}

.katal .confirmationModal .cancelButton,
.katal .confirmationModal .okButton {
  margin-right: 16px;
  width: 230px;
  height: 40px;
  --kat-button-color: #008296;
}

.katal .confirmationModal .okButton {
  --kat-button-color: #ffffff;
}

.queryIdentifier {
  display: flex;
  align-items: center;
}

.queryId {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #01222d;
}

.queryLanguage {
  color: #697576;
  font-size: 17px;
}

.queryStatusDetails {
  background-color: #f2fafd;
  padding: 10px 10px;
  margin-bottom: 23px;
  margin-top: 15px;
}

.queryStatus {
  display: flex;
}

.flex {
  display: flex;
}

.statusDetails {
  display: grid;
  padding: 10px;
  flex: 1 1 auto;
  border-right: 1px solid #c4c4c4;
  text-align: left;
  margin-left: 20px;
}

.duration {
  border: none;
}

.queryTime {
  font-size: 12px;
  margin-left: 8px;
}

.statusLabel {
  color: #697576;
  font-size: 15px;
}

.statusValue {
  color: #01222d;
  font-size: 17px;
}

.queryContents {
  background-color: #d3e2e8;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
}

.queryBlock {
  background-color: #e7e9e9;
  padding: 10px 16px;
  width: fit-content;
  border-radius: 5px;
  margin-top: 26px;
}

.reply {
  margin-left: auto;
  background-color: #077398;
  & * {
    color: #fff !important;
  }
}

.querySection {
  padding: 0 25px 25px 25px;
}

.userIdentifier {
  font-weight: bold;
  color: #666666;
  font-size: 14px;
  margin-bottom: 5px;
}

.messageContent {
  font-size: 15px;
  color: #3a3a3a;
}

.profileIcon {
  height: 54px;
  width: 54px;
  border-radius: 50%;
  margin-right: 11px;
}

.katal .previewModal .body {
  border: none;
}

.kat-group-horizontal {
  width: 100%;
}

.kat-group-horizontal .previewButton {
  width: 100%;
}

.katal .previewModal kat-box[variant="white-junglemist"] {
  --kat-box-background: #f7f7f7;
  overflow-y: auto;
  height: 400px;
}
.form-input,
.form-select {
  --border-radius: 4px;
}

.form-select {
  --option-background: #fff;
  --option-background-hover: #f9f5fa;
  --option-hover-ring-width: 0;
  --option-background-focus: #f5f9fa;
}

.status-DRAFT {
  --background: #fef2b8;
  --border-color: #dcb700;
  --hover-ring-color: #dcb700;
  --focus-ring-color: #dcb700;
}

.status-ACTIVE {
  --background: #dcf3a0;
  --border-color: #98c91e;
  --hover-ring-color: #98c91e;
  --focus-ring-color: #98c91e;
}

.status-INACTIVE {
  --background: #f9d5e2;
  --border-color: #ad0a30;
  --hover-ring-color: #ad0a30;
  --focus-ring-color: #ad0a30;
}
.multiselect {
  line-height: 2em;
  &-container {
    position: relative;
    &-options {
      position: absolute;
      top: 103%;
      z-index: 999;
      width: 100%;
      padding: 1em;
      max-height: 11rem;
      overflow: auto;
      &-item {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.text-transparent {
  color: transparent;
}

.outlined-button {
  --border-width: 2;
  --box-shadow: none;
  --border-radius: 4px;
  --color: #077398;
  --border-color: #077398;
  --focus-ring-color: #077398;
  --background-focus: #fff;
  --hover-ring-color: #077398;
  --focus-ring-width: 1px;
  --background-hover: #fff;
}

.blue-button {
  --border-width: 2;
  --box-shadow: none;
  --border-radius: 4px;
  --background: #077398 !important;
}

.table-action {
  --background: transparent;
  --box-shadow: none;
  --border-width: 0;
  --focus-ring-color: 0;
  --focus-ring-outlay: 0;
  --focus-ring-width: 0;
  --background-hover: transparent;
  --padding: 0;

  --background-disabled: transparent;
  --color-disabled: rgb(166, 161, 161);
}

.table-action-delete {
  --color: #cc0c39;
}

.table-action-edit {
  --color: #077398;
  margin-right: 2rem;
  & div {
    white-space: nowrap;
  }
}

.table-action-icon {
  width: 15px;
  color: inherit;
}

.shadow-box {
  --border-radius: 14px;
}

h5 {
  margin-bottom: 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100vh;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
  }
  &-dialog {
    background-color: #fff;
    width: 40%;
    padding: 2em;
    border-radius: 4px;
    &-title {
      text-align: center;
      font-weight: bolder !important;
    }
    &-footer {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0.5em 3rem 0;
      column-gap: 1rem;
      &-button {
        margin: 10px;
        display: block;
      }
    }
  }
}

.hide-popup {
  visibility: hidden;
}

.file-name-blue-box {
  background: #f2fafd;
  border-radius: 4px;
  padding: 0.8rem 1rem;
  font-weight: 400;
  font-size: 14px;
  color: #01222d;
}

.flex-wrap  {
  flex-wrap: wrap;
}

.active-queries {
  display: grid !important;
  grid-template-columns: 320px auto;
  height: 100%;
  flex-wrap: nowrap;
  height: 92vh;
  &-profiles,
  &-chats {
    overflow-y: auto;
  }
  &-select-chats {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  &-profiles {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    background-color: #fff;
    &-profile {
      &.active-queries-profiles-profile-selected {
        background-color: #f2fafd;
      }
      width: 100%;
      display: block !important;
      text-align: left;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0.5em 1rem;
      &:hover {
        background-color: #f2fafd;
      }
      & article {
        display: grid !important;
        grid-template-columns: 38px auto;
        column-gap: 0.8rem;
        & aside {
          height: 38px;
          width: 38px;
          border-radius: 100%;
          overflow: hidden;
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  &-chats {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    height: 90vh;

    &-header {
      top: 0;
      left: 0;
      &-profile {
        & aside {
          height: 54.62px;
          width: 54.62px;
          border-radius: 100%;
          overflow: hidden;
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &-end-call {
          font-weight: bold;
          --border-width: 2;
          --box-shadow: none;
          --border-radius: 65px;
          --color: crimson;
          --border-color: crimson;
          --focus-ring-color: crimson;
          --background-focus: #fff;
          --hover-ring-color: crimson;
          --focus-ring-width: 1px;
          --background-hover: #fff;
          --background: #fff;
          --color-hover: crimson;
        }
      }
      &-details {
        background: #f2fafd;
        display: grid !important;
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &-footer {
      margin-top: auto;
      background-color: #f7f7f7;
      padding: 0.1rem 2rem 0.9rem;
      display: flex !important;
      column-gap: 1rem;
      align-items: flex-end;
      &-input {
        display: flex !important;
        align-items: end !important;
        column-gap: 1rem;
        width: 100%;
        & .lang-selection {
          --visibility: hidden;
          --indicator-height: 0px;
          --indicator-width: 0px;
          --indicator-size: 0px;
          --border-color: transparent;
          --background: transparent;
          --focus-ring-color: transparent;
          --hover-ring-color: transparent;
          --menu-background: #fff;
          --padding: 1px;
          --icon-padding: 0px !important;
          & .select-options {
            bottom: 0px !important;
          }
        }
        & .input-group {
          --border-radius: 4px !important;
          & .typeHere {
            --border-radius: 4px !important;
          }
          width: 100%;
          & svg {
            cursor: pointer;
            &:focus {
              color: red;
            }
          }
        }
      }
      #send-button {
        background: #1289b9;
        border: none;
        border-radius: 100%;
        color: #fff;
        height: 40px;
        width: 40px;
        cursor: pointer;
      }
      #translate-button {
        color: #1289b9;
        border-radius: 100%;
        height: 35px;
        width: 35px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: 1px solid #077398;
        cursor: pointer;
      }
      .attachment-button {
        background-color: transparent;
        border: none;
        color: #979797;
        cursor: pointer;
        font-size: 50px;
        display: flex;
        align-items: center;
        position: relative;
        &-icon {
          transform: rotateZ(-50deg);
          --font-size: 30px;
          --height: 10px;
        }
        &-input {
          height: 30px;
          width: 30px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 999999;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    &-main {
      overflow-y: auto;
      padding: 1rem 2rem;
      &-sent,
      &-received {
        border-radius: 25px;
        padding: 1rem 1.2rem;
        width: 52%;
        margin-bottom: 0.5rem;
        & header {
          font-weight: 700;
          margin-bottom: 0.5rem;
          & span {
            padding-right: 0.5rem;
          }
        }
        & p {
          margin-bottom: 0 !important;
        }
        &-media-container{
          aspect-ratio: 16/9;
          height: 100%;
          width: 100%;
          margin-bottom: 1rem;
        }
        &-img {
          aspect-ratio: 16/9;
          height: 100%;
          width: 100%;
          display: block;
          object-fit: cover;
        }
        &-video {
          aspect-ratio: 16/9;
          height: 100%;
          width: 100%;
          display: block;
          object-fit: cover;
        }
        &-audio {
          width: 100%;
          border: 1px solid #ccc;
          background-color: #f2fafd;
          border-radius: 10rem;
          &::-webkit-media-controls-play-button,
          &::-webkit-media-controls-panel {
            background-color: #f2fafd;
            color: #1289b9;
          }
        }
        & footer {
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;
        }
      }

      &-sent {
        background: #1289b9;
        color: #fff;
        margin-left: auto;
        & header {
          color: #fff;
        }
      }
      &-received {
        background: #f7f7f7;
        & header {
          color: #666666;
        }
      }
    }
  }
}

.divider {
  background: #c4c4c4;
  margin: 0 !important;
  padding: 0 !important;
}
.transparent-badges {
  --background: transparent;
  --color: #697576;
  --border-color: rgba(153, 167, 171, 0.2);
  --border-width: 1px;
  --border-style: solid;
  font-size: 12px;
  line-height: 14px;
}

.blue-link-button {
  --background: transparent;
  --box-shadow: none;
  --border-width: 0;
  --focus-ring-color: 0;
  --focus-ring-outlay: 0;
  --focus-ring-width: 0;
  --background-hover: transparent;
  --padding: 0;
  --color: #1289b9;
  width: fit-content;
  display: flex;
}

.html-symbol {
  font-size: 16px;
}

.cp-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  &-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &-main {
    width: 40%;
    margin-left: auto;
    background-color: #fff;
    height: 100%;
    &-container {
      display: grid !important;
      height: 100vh;
      grid-template-rows: 1fr auto;
      &-stepper {
        overflow-y: auto !important;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      padding: 1rem;
      margin-bottom: 0;
      line-height: 0px;
      &-icon {
        font-size: 25px;
        width: fit-content;
        height: 0px;
        cursor: pointer;
      }
    }
    &-blue-strip {
      background: #f2fafd;
      display: flex !important;
      justify-content: space-between;
      padding: 1rem 0;
      &-item {
        border-right: 1px solid #c4c4c499;
        padding: 0rem 0;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &:last-child {
          border: none;
        }
      }
      &-black {
        font-size: 15px;
        text-align: left;
      }
    }
  }
}

.plan-stepper {
  overflow-y: auto;
  height: 100%;
  padding-right: 1rem;
  &-main {
    display: grid !important;
    grid-template-columns: auto auto minmax(auto, 100%);
    column-gap: 1rem;
    max-height: fit-content;
    &-col1 {
      padding-top: 0.2rem;
      // width: 16ch;
    }
    &-col2 {
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 0.5rem;
      height: 100%;
      padding-bottom: 0.5rem;

      &-path {
        height: 100%;
        width: 5px;
        background: #b9e8f7;
        margin: auto;
        border-radius: 5px;
      }
    }
    &-col3 {
      width: 100%;
      height: fit-content;
      &-images-grid {
        display: grid !important;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin-top: 1rem;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;
          border-radius: 8px;
          margin-bottom: 1rem;
          border: 1px solid #ccc !important;
        }
      }
    }
  }
}

.badge {
  background: #cc0c39;
  color: #ffffff;
  font-size: 10px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cpp {
  background-color: #fff;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  display: grid;
  height: 91.5vh;
  grid-template-areas: "title title" "aside details" "aside details";
  grid-template-columns: 4fr 2fr;
  grid-template-rows: auto 1fr;
  &-title {
    grid-area: title;
  }
  &-aside {
    grid-area: aside;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  &-stepper {
    height: 100%;
    overflow: auto;
  }
  &-details {
    grid-area: details;
  }
}

.overflowy-auto {
  overflow: auto !important;
}

.attachment-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.close-icon {
  position: absolute;
  top: -12%;
  right: 0;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.color-amazon {
  color: #077398 !important;
  & * {
    color: #077398 !important;
  }
}

#shadow-root .option-inner-container {
  height: 200px !important;
}

.select {
  &-label {
    display: block !important;
    padding-bottom: 0.3rem;
  }
  &-container {
    position: relative;
    &:has(.disabled) {
      cursor: not-allowed;
    }
  }
  &-input {
    width: 100%;
    height: 2.125rem;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px 0 #e6e6e6;
    cursor: pointer;
    border: 1px solid #a9b7b8;
    &.open {
      border: 2px solid #008295;
    }
    &-icon {
      padding-top: 3px;
    }
    &.disabled {
      pointer-events: none;
      background-color: #f3f6f6;
    }
  }
  &-options {
    position: absolute;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    border: 1px solid #a9b7b8;
    max-height: 15rem;
    overflow: auto;
    &.top {
      bottom: 100%;
    }
    &-option {
      padding: 0.5rem;
      cursor: pointer;
      border: 2px solid;
      border-color: transparent;
      &:hover {
        border-color: #008295;
      }
      &.selected {
        background-color: #f5f9fa;
      }
    }
  }
}

kat-table-cell {
  vertical-align: middle !important;
}

kat-table-head {
  background-color: #f0f1f2 !important;
}

.nowrap {
  white-space: nowrap;
}
.help-icon {
  font-size: 30px !important;
}

.text-overflow {
  overflow-wrap: break-word;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15rem;
}

.ms-auto {
  margin-left: auto;
}

.respondBtn {
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
}

.overflow-auto {
  overflow: auto;
}
.preview-query-close-icon {
  position: absolute;
  top: -5.5%;
  right: 0;
  cursor: pointer;
}

.new-queries-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
}

.react-player__shadow{
  padding: 100px;
}

.cc-dropdown {
  text-align: right;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cc-dropdown-container {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: right;
}

.side-nav-item{
  min-height: 3.6rem;
}

.modal-list {
  margin-top: 10rem;
  z-index: 100;
}

.modal-input-group {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.kat-modal {
  --body-overflow: visible;
}